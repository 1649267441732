<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">线上课成绩</div>
      <div class="bt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="returnbt">
        <div class="lefttitle">
          <el-radio v-model="radio" label="1" @change="radiobt">学习进度</el-radio>
          <el-radio v-model="radio" label="2" @change="radiobt">作业考试成绩</el-radio>
        </div>
      </div>
      <component ref="customcomponent" :is="componentId"></component>
    </div>
  </div>
</template>

<script>
import Learnprogress from "@/views/wisdompracticeteaching/components/courselearning/components/courseoutline.vue";
import Testscore from "./testscore.vue";
import Customdialog from "@/components/customdialog.vue";
import { getcourseinfo, getCourseSemesterInfo } from "@/api/course";

export default {
  name: "mainpage",
  components: {
    Customdialog,
    Learnprogress,
    Testscore,
  },
  data() {
    return {
      courseSn: "", // 课程编号
      courseInfo: {}, // 课程信息
      courseKey: "", // 加入课程的钥匙
      courseSemesterId: "", // 课程课期ID
      courseSemesterSn: "", // 课程课期ID
      courseSemesterInfo: {}, // 课程课期信息
      courseSemesters: [], // 课程课期列表
      componentId: "Learnprogress",
      radio: "1",
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      value: "",
      item: "",
    };
  },
  computed: {},
  created() {
    this.courseSn = decodeURIComponent(this.$route.query.course_sn);
    this.initdata();
    this.item = this.$route.query;
    //console.log(this.item)
  },
  methods: {
    // 初始化页面
    initdata() {
      if (this.$route.query.course_semester_sn) {
        this.courseSemesterSn = decodeURIComponent(
          this.$route.query.course_semester_sn
        );
        this.setCourseSemester();
      } else {
        this.getcourseinfo();
      }
    },
    // 获取课程详细信息
    getcourseinfo() {
      const params = { course_sn: this.courseSn };
      getcourseinfo(params)
        .then((res) => {
          if (res.code === 0 && res.data) {
            // console.log("课程详细信息", res.data);
            this.courseInfo = res.data.course_info; // 自定义对象接收课程详情
            this.courseSemesterId = this.courseInfo.newest_course_semester_id;
            this.courseSemesterInfo = res.data.info; // 自定义对象接收课程课期详情
            this.courseSemesterSn = this.courseSemesterInfo.course_semester_sn;
            this.courseKey = this.courseSemesterSn;
            this.$refs.customcomponent.initdata(
              this.courseSemesterInfo,
              this.courseKey
            );
          } else if (res.code === 3) {
            // this.$message.warning('该课程不存在或已下架!')
            this.$router.push({
              path: "/home/wisdompracticeteaching/mainpage",
            });
          }
        })
        .catch(() => { });
    },
    // 设置课期
    setCourseSemester() {
      const params = { course_semester_sn: this.courseSemesterSn };
      // 获取课期详情
      getCourseSemesterInfo(params).then((res) => {
        if (res.code === 0 && res.data) {
          this.courseInfo = res.data.course_info; // 自定义对象接收课程详情
          this.courseSemesterId = this.courseInfo.newest_course_semester_id;
          this.courseSemesterInfo = res.data.info; // 自定义对象接收课程课期详情
          this.courseSemesterSn = this.courseSemesterInfo.course_semester_sn;
          this.courseKey = this.courseSemesterSn;
          this.$refs.customcomponent.initdata(
            this.courseSemesterInfo,
            this.courseKey
          );
        }
      });
    },
    radiobt(value) {
      if (Number(value) === 1) {
        this.componentId = "Learnprogress";
      } else if (Number(value) === 2) {
        this.componentId = "Testscore";
      }
      this.$nextTick(() => {
        this.$refs.customcomponent.initdata(
          this.courseSemesterInfo,
          this.courseKey
        );
      })
    },
    // 返回列表
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 1,
          componentId: "Mycourses",
        },
      });
    },
    // 进入详情
    todetail() {
      this.$router.push({
        path: "/personalcenter/interactiveqadetails",
      });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    background-color: #fff;
    min-height: calc(100vh - 240px);

    .head {
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      padding-top: 10px;
      height: 40px;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }

      .elinput {
        width: 300px;

        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 1px solid #cccccc;
    }

    .maincontain {
      border-radius: 4px;
      // padding: 20px;
      margin-bottom: 20px;

      .returnbt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;

        .lefttitle {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    .bt {
      margin-right: 10px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .mainpage {
    background-color: #fff;
    min-height: calc(100vh - 240px);

    .head {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      padding-top: 10px;
      height: 40px;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }

      .elinput {
        width: 300px;

        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 1px solid #cccccc;
    }

    .maincontain {
      border-radius: 4px;
      padding: 20px;
      margin-bottom: 20px;

      .returnbt {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .lefttitle {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    .bt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>
